<template>
  <div class="_main_wrapper relative flex">
    <div class="i-left-panel shadow-one">
      <workspace-left-menu/>
      <button v-if="canCreate(project.cans)" @click="activeComponent=CreateScenario; showModal = true;" class="ws-folder-button focus:outline-none hover:bg-secondary-five text-primary-one fs-14 font-poppins fw-600 bg-primary-three shadow-one w-full mt-5 rounded flex items-center justify-center">
        Create Scenario
      </button>
      <div class="ws-folder-box overflow-y-auto scenario-left-box">
        <div class="flex justify-between cursor-pointer text-primary-one opacity-60" @click="toggleDisplay('all')"
             :class="{'opacity-100': displayMode === 'all'}">
          <h2 class="fs-14 font-poppins fw-600">All Scenario</h2>
          <h2 class="fs-14 font-poppins fw-600">{{ scenarios.length }}</h2>
        </div>
        <div class="flex justify-between cursor-pointer text-primary-one opacity-60 mt-8" @click="toggleDisplay('own')"
             :class="{'opacity-100': displayMode === 'own'}">
          <h2 class="fs-14 font-poppins fw-600">Own Scenario</h2>
          <h2 class="fs-14 font-poppins fw-600">{{ getOwnScenarioCount() }}</h2>
        </div>
<!--        <div class="flex justify-between cursor-pointer text-primary-one opacity-60 mt-8"-->
<!--             @click="toggleDisplay('trashed')" :class="{'opacity-100': displayMode === 'trashed'}">-->
<!--          <h2 class="fs-14 font-poppins fw-600">Deleted</h2>-->
<!--          <h2 class="fs-14 font-poppins fw-600">{{ scenarios.filter(item => item.deleted_at).length }}</h2>-->
<!--        </div>-->
      </div>
    </div>
    <div class="i-right-panel">
      <div class="ws-heading-box relative flex flex-col pb-8">
        <workspace-menu/>
        <h2 class="fs-14 fw-700 font-poppins text-secondary-one absolute page-heading-title">Scenario Management</h2>

        <div class="ws-action-box flex items-center justify-between ws-select-dropdown">
          <div class="flex scenario-action-box">
            <div class="flex">
              <div class="relative i-border-1 border-secondary-two rounded ws-search-width">
                <input
                    type="text"
                    class="focus:outline-none py-2 font-poppins ws-search-box bg-transparent text-secondary-one placeholder-secondary-two"
                    :placeholder="searchField.placeholder"
                    v-model="searchField.text"
                    @click="searchField.placeholder = ''"
                    v-click-outside="outsideSearchInput"
                />
                <div class="mr-2 flex items-center justify-between absolute inset-y-0 right-0 pointer-events-none pr-1">
                  <img
                      class="w-3 h-3"
                      src="/images/icons/search_icon.svg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="scenario-content-box pl-10 mr-8 mt-8 overflow-y-auto ws-scrollbar">
        <div class="scenarioCards grid grid-cols-3 gap-y-8">
          <DataCard
            v-for="(item, index) in getDisplayData"
            :key="index+Math.floor(Math.random()*1000)"
            :routeObj="{ name: 'programmatic.scenario.view', params: { id: $route.params.id, scenario_id: item.id } }"
            :title="item.title"
            :category="'Category: '+ item.category"
            :data="[
              {Created: item.created_at},
              {Last_Edited: $lodash.format(item.updated_at, 'dd/MM/yyyy')},
            ]"
            :editCallBack="() => { dModal.edit = true; editModal(item) }"
            :duplicateCallBack="() => { duplicate(item) }"
            :deleteCallBack="() => { deleteScenario(item) }"
            :defaultSetCallBack="() => { setDefault(item) }"
            :color="(item.settings && item.settings.color) ? item.settings.color : defaultColor"
            :isDefault="item.is_default"
            :image="item?.file?.file_path"
          />
        </div>
      </div>
    </div>


    <Modal v-model="showModal">
      <component
        :scenarioId="editableScenarioId"
        ref="editScenario"
        :is="activeComponent"
        @closeModal="showModal=false" />
    </Modal>

    <ps-e-modal :dModal="dModal.edit" :scenarioId="editableScenarioId" @hideEditModal="dModal.edit = false" ref="editScenario"/>
  </div>
</template>

<script setup>
  import Modal from "@/elements/atom/Modal"
  import CreateScenario from '@/components/programmatic/CreateScenario.vue'
  import EditScenario from '@/components/programmatic/EditScenario.vue'
  import DataCard from '@/elements/DataCard.vue'

  import usePermission from '@/composable/usePermission.js'
  const { canCreate } = usePermission()

  import { ref } from 'vue'
  const activeComponent = ref()
  const showModal = ref(false)
</script>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex';

export default {
  name: "Scenario",
  created() {
    document.title = 'Scenario';
    const self = this;
    self.$store.dispatch("workspace/projectSelection", self.$route.params.id);
    this.$store.dispatch("project/getSolidObject", this.$route.params.id);
    self.$store.dispatch("programmatic/loadScenarios", self.$route.params.id);
  },
  data() {
    return {
      dModal: {
        create: false,
        edit: false
      },
      searchField: {
        placeholder: 'Search',
        text: ''
      },
      hoveredIndex: '',
      editableScenarioId: null,
      displayMode: null,
      defaultColor: '#2b80ad'
    }
  },
  computed: {
    ...mapGetters({
      projectSelection: 'workspace/projectSelection',
      scenarios: 'programmatic/scenarios',
      project: 'project/project',
    }),
    getDisplayData() {
      if (this.displayMode === 'all') {
        return this.displayAll();
      } else if (this.displayMode === 'own') {
        return this.displayOwnData();
      } else if (this.displayMode === 'trashed') {
        return this.displayTrashedData();
      }

      if (this.searchField.text) {
        return this.scenarios.filter((item) => {
          return this.searchField.text
              .toLowerCase()
              .split(" ")
              .every((v) => item.title.toLowerCase().includes(v) && (!item.deleted_at || false || false));
        });
      }
      return this.scenarios.filter(item => !item.deleted_at || false || false);
    }
  },
  methods: {
    toggleDisplay(mode) {
      this.displayMode = mode;
    },

    setDefault(item)
    {
      const self = this;
      const defaultScenario = this.getDisplayData.filter(item=>item.is_default)
      defaultScenario.forEach(element => {
        const data = {
          id: element.id,
          category: element.category,
          title: element.title,
          is_default: 0,
        }
        axios.post(`projects/scenarios/${element.id}/update`, data).then(response => {
          self.$store.dispatch("project/getSolidObject", self.$route.params.id)
        })
      })

      const data = {
        id: item.id,
        category: item.category,
        title: item.title,
        is_default: 1
      }

      axios.post(`projects/scenarios/${item.id}/update`, data).then(response => {
        self.$store.dispatch("project/getSolidObject", self.$route.params.id)
      })
    },

    getOwnScenarioCount() {
      let user = JSON.parse(localStorage.getItem('_user'));
      if (user && user.id) {
        return this.scenarios.filter(item => (!item.deleted_at || false || false) && parseInt(item.created_by) === parseInt(user.id)).length;
      }
      return 0;
    },
    displayAll() {
      if (this.searchField.text) {
        return this.scenarios.filter((item) => {
          return this.searchField.text
              .toLowerCase()
              .split(" ")
              .every((v) => item.title.toLowerCase().includes(v));
        });
      }
      return this.scenarios;
    },
    displayOwnData() {
      let user = JSON.parse(localStorage.getItem('_user'));
      if (user && user.id) {
        if (this.searchField.text) {
          return this.scenarios.filter((item) => {
            return this.searchField.text
                .toLowerCase()
                .split(" ")
                .every((v) => item.title.toLowerCase().includes(v) && (!item.deleted_at || false || false) && parseInt(item.created_by) === parseInt(user.id));
          });
        }
        return this.scenarios.filter(item => (!item.deleted_at || false || false) && parseInt(item.created_by) === parseInt(user.id));
      }
      return [];
    },
    displayTrashedData() {
      if (this.searchField.text) {
        return this.scenarios.filter((item) => {
          return this.searchField.text
              .toLowerCase()
              .split(" ")
              .every((v) => item.title.toLowerCase().includes(v) && item.deleted_at);
        });
      }
      return this.scenarios.filter(item => item.deleted_at);
    },
    outsideSearchInput() {
      this.searchField.placeholder = 'Search';
    },
    mouseOver(event, index) {
      this.hoveredIndex = index;
    },
    outsideOption() {
      this.hoveredIndex = '';
    },

    editModal(item) {
      this.editableScenarioId = item.id;
      this.dModal.edit = true;
      this.$refs.editScenario.setScenario(item);
    },

    deleteScenario(item) {
      const self = this;
      if (confirm("Do you want to delete this?")) {
        self.$store.dispatch("programmatic/deleteScenario", item.id).then(response => {
          if (response) {
            self.$store.dispatch("programmatic/loadScenarios", self.$route.params.id);
          } else {
            alert('Something went wrong!!!');
          }
        })
      }
    },
    duplicate(item) {
      this.$Progress.start();
      this.$store.dispatch("programmatic/duplicate", item).then(response => {
        this.$Progress.finish();
      });
    }
  }
}
</script>

<style scoped>
/*   @media all and (max-width: 1850px) and (min-width: 1300px){
    .scenarioCards{
      grid-template-columns: repeat(auto-fit, minmax(370px, 1fr));
      gap: 32px;
    }
    .scenarioCards>.scenario-item-box{
      max-width: 600px;
      width: 100%;
    }
  } */
</style>
